import React from "react";

function Score({ score, total }) {
  const percentage = (score / total) * 100;

  return (
    <div className="score-container">
      <h2>Quiz terminé !</h2>

      <div className="circular-progress">
        <svg viewBox="0 0 36 36" className="circular-chart">
          <path
            className="circle-bg"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${percentage}, 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
        <div className="score-text">
          {score}/{total}
        </div>
      </div>

      <button className="button-22" onClick={() => window.location.reload()}>
        Recommencer le quiz
      </button>
    </div>
  );
}

export default Score;
