import "./styles/css/main.css";
import { Header, Footer } from "./components/commons";
import React, { useState, useEffect } from "react";
import Question from "./components/Question";
import ProgressBar from "./components/ProgressBar";
import Score from "./components/Score";

function App() {
  // CONSTANTE
  const headerTitle = "Questionnaire de sécurité";
  const formation = "FISA 3A INFO 24-27";
  const member = "Mathias - Grégoire - Matthéo";

  // État pour les questions, la question actuelle, et le score
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);

  // Charger les questions depuis le fichier JSON
  useEffect(() => {
    fetch("/bloc-admin-reseaux/question.json")
      .then((response) => response.json())
      .then((data) => setQuestions(data));
  }, []);

  // Passer à la question suivante
  const handleNextQuestion = (isCorrect) => {
    if (isCorrect) {
      setScore((prev) => prev + 1); // Augmente le score si la réponse est correcte
    }
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion((prev) => prev + 1); // Passe à la question suivante
    } else {
      setIsQuizCompleted(true); // Termine le quiz
    }
  };

  const progress = ((currentQuestion + 1) / questions.length) * 100;

  return (
    <div id="cesi">
      <Header title={headerTitle} />
      <main>
        {isQuizCompleted ? (
          <Score score={score} total={questions.length} />
        ) : questions.length > 0 ? (
          <div className="outer-question-container">
            <Question
              questionData={questions[currentQuestion]}
              onNext={handleNextQuestion}
            />
            <ProgressBar progress={progress} />
          </div>
        ) : (
          <p>Chargement des questions...</p>
        )}
      </main>
      <Footer formation={formation} member={member} />
    </div>
  );
}

export default App;
