// src/components/Modal.js
import React from "react";

function Modal({ explanation, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Explication</h3>
        <p>{explanation}</p>
        <button className="button-22" onClick={onClose}>
          Fermer
        </button>
      </div>
    </div>
  );
}

export default Modal;
