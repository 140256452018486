import React from "react";
import logo from "../images/common/logo.png";

export const Header = ({ title }) => {
  return (
    <div id="cesi-header">
      <img src={logo} alt="logo" />
      <h1>{title}</h1>
    </div>
  );
};

export const Footer = ({ formation, member }) => {
  return (
    <div id="cesi-footer">
      <p className="formation">{formation}</p>
      <div>
        <p>{member}</p>
      </div>
    </div>
  );
};
