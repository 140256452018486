import React, { useState } from "react";
import Modal from "./Modal"; // Importation du composant Modal

function Question({ questionData, onNext }) {
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [isValidated, setIsValidated] = useState(false);
  const [showModal, setShowModal] = useState(false); // État pour afficher la popup

  // Validation de la réponse
  const handleValidation = () => {
    setIsValidated(true); // Active la validation
    setShowModal(true); // Ouvre la modal avec l'explication
  };

  // Fermer la modal et passer à la question suivante
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Passer à la question suivante
  const handleNext = () => {
    onNext(questionData.choices[selectedChoice]?.isCorrect || false);
    setSelectedChoice(null);
    setIsValidated(false);
  };

  return (
    <div>
      <h2>{questionData.question}</h2>
      <form>
        {questionData.choices.map((choice, index) => (
          <div
            key={index}
            className="question-item"
            style={{
              backgroundColor: isValidated
                ? selectedChoice === index
                  ? choice.isCorrect
                    ? "#32c80873" // green
                    : "#ff000059" // red
                  : ""
                : "",
            }}
          >
            <label>
              <input
                type="radio"
                name="choice"
                value={index}
                onChange={() => setSelectedChoice(index)}
                checked={selectedChoice === index}
                disabled={isValidated} // Désactive les boutons après validation
              />
              {choice.text}
            </label>
          </div>
        ))}
      </form>
      {!isValidated ? (
        <button
          className="button-22"
          onClick={handleValidation}
          disabled={selectedChoice === null}
        >
          Valider
        </button>
      ) : (
        <button className="button-22" onClick={handleNext}>
          Suivant
        </button>
      )}

      {/* Affichage de la modal si la validation est effectuée */}
      {showModal && (
        <Modal
          explanation={questionData.choices[selectedChoice]?.explanation}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default Question;
